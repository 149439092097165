import { Report } from '@/models/Report';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Rpc } from '@/models/Rpc';
import { DateFormatter } from '@/support/DateFormatter';

@Component<PlanningNoAppointmentDialog>({})
export default class PlanningNoAppointmentDialog extends Vue {
  @Prop()
  protected report!: Report;

  @Prop({ default: () => [
    {
      label: 'Geen gehoor',
      value: 'no_answer',
    },
    {
      label: 'Terugbel afspraak',
      value: 'callback',
    },
    {
      label: 'Niet meer nodig',
      value: 'discarded',
    },
  ] })
  protected reasons!: NoAppointmentReason[];

  protected noAnswerReasons = [
    {
      label: 'GGH1',
      value: 'ggh1',
    },
    {
      label: 'GGH2',
      value: 'ggh2',
    },
    {
      label: 'GGH3',
      value: 'ggh3',
    },
    {
      label: 'GGH4',
      value: 'ggh4',
    },
  ];

  protected selectedNoAnswerReason = '';

  protected callbackReasons = [
    {
      label: 'TBV',
      value: 'tbv',
    },
    {
      label: 'Risicogroep',
      value: 'risk',
    },
    {
      label: 'Corona',
      value: 'corona',
    },
  ];

  protected selectedCallbackReason = '';

  protected mounted() {
    //
  }

  protected mask: Mask = { time: '##:##' };

  protected selectedReason = '';

  protected comment = '';

  protected isLoading = false;

  protected date = new DateFormatter();

  protected close() {
    this.$emit('input', false);
  }

  protected saveNoAppointment() {
    if (this.isExpertPlanning) {
      this.handlePlanningOrganization();
      return;
    }

    this.handleNoAnswer();
  }

  protected handleNoAnswer() {
    if (! this.report) {
      return;
    }

    this.isLoading = true;

    const payload: {[key: string]: string} = {
      report: this.report.uuid as string,
      type: this.selectedReason,
      description: this.comment,
    };

    if (this.selectedReason === 'no_answer' && this.selectedNoAnswerReason) {
      payload.subtype = this.selectedNoAnswerReason;
    }

    if (this.selectedReason === 'callback' && this.selectedCallbackReason) {
      payload.subtype = this.selectedCallbackReason;
    }

    if (this.date.selectedDate) {
      payload.next_action_at = this.date.selectedDate;
    }

    new Rpc()
      .rpcPost({
        signature: 'appointment:not-possible',
        body: payload,
      })
      .then(() => {
        this.isLoading = false;
        this.$emit('noAppointmentConfirm');
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected async handlePlanningOrganization() {
    if (! this.report) {
      return;
    }
    this.isLoading = true;

    const payload: {[key: string]: string} = {
      status: 'planning_organization',
      planned_at: '',
    };

    if (this.date.selectedDate) {
      payload.deadline_at = this.date.selectedDate;
    }

    this.report.update(payload).then(() => {
      this.isLoading = false;
      this.$emit('noAppointmentConfirm');
    })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected get isDisabled() {
    return ! this.selectedReason;
  }

  protected get showDeadlineDate(): boolean {
    return this.selectedReason === 'callback'
      || this.selectedReason === 'cancelled'
      || this.selectedReason === 'needs_replanning';
  }

  protected get isExpertPlanning(): boolean {
    return this.selectedReason === 'cancelled'
      || this.selectedReason === 'needs_replanning';
  }

  protected get showDeadlineLabel(): string {
    return this.isExpertPlanning ? 'Inplannen op/voor:' : 'Datum terugbelafspraak';
  }
}

export interface NoAppointmentReason {
  label: string;
  value: string;
  // value: planningStatus;
}

interface Mask {
  [key: string]: string;
}

type planningStatus = 'unplanned' | 'no_answer' | 'replan' | 'follow_up_appointment' | 'further_research' | 'additional_research'| 'planned'| 'no_action_required';
