var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "defaultDialog" },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-icon",
                        _vm._g(
                          {
                            staticClass: "dialog-close",
                            on: { click: _vm.close }
                          },
                          on
                        ),
                        [_vm._v("close")]
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v("Sluiten")])]
          ),
          _c(
            "v-container",
            { attrs: { fluid: "", "grid-list-lg": "", "pa-0": "" } },
            [
              _c(
                "v-layout",
                { attrs: { wrap: "", "align-end": "" } },
                [
                  _c("v-flex", { attrs: { xs12: "", "mb-3": "" } }, [
                    _c("h2", { staticClass: "dialog-title" }, [
                      _vm._v("Geen afspraak kunnen inplannen")
                    ])
                  ]),
                  _vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("LoaderCard", {
                              attrs: { flat: "", type: "spinner--center" }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  !_vm.isLoading
                    ? [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.reasons,
                                label: "Reden",
                                "item-text": "label",
                                "item-value": "value"
                              },
                              model: {
                                value: _vm.selectedReason,
                                callback: function($$v) {
                                  _vm.selectedReason = $$v
                                },
                                expression: "selectedReason"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.selectedReason === "no_answer"
                              ? _c("v-select", {
                                  attrs: {
                                    items: _vm.noAnswerReasons,
                                    label: "Geen Gehoor reden",
                                    "item-text": "label",
                                    "item-value": "value"
                                  },
                                  model: {
                                    value: _vm.selectedNoAnswerReason,
                                    callback: function($$v) {
                                      _vm.selectedNoAnswerReason = $$v
                                    },
                                    expression: "selectedNoAnswerReason"
                                  }
                                })
                              : _vm._e(),
                            _vm.selectedReason === "callback"
                              ? _c("v-select", {
                                  attrs: {
                                    items: _vm.callbackReasons,
                                    label: "Terugbel Afspraak reden",
                                    "item-text": "label",
                                    "item-value": "value"
                                  },
                                  model: {
                                    value: _vm.selectedCallbackReason,
                                    callback: function($$v) {
                                      _vm.selectedCallbackReason = $$v
                                    },
                                    expression: "selectedCallbackReason"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.showDeadlineDate
                          ? _c(
                              "v-flex",
                              { attrs: { sm7: "" } },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "dialog",
                                    attrs: {
                                      "close-on-content-click": false,
                                      lazy: "",
                                      "nudge-right": "100",
                                      "full-width": "",
                                      "max-width": "290px",
                                      "min-width": "290px"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      staticClass: "input input-date",
                                      attrs: {
                                        slot: "activator",
                                        placeholder: "DD-MM-YYYY",
                                        label: _vm.showDeadlineLabel,
                                        clearable: "",
                                        "error-messages": _vm.date.errorMessage
                                      },
                                      on: {
                                        "click:clear": function($event) {
                                          return _vm.date.clear()
                                        }
                                      },
                                      slot: "activator",
                                      model: {
                                        value: _vm.date.formattedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.date,
                                            "formattedDate",
                                            $$v
                                          )
                                        },
                                        expression: "date.formattedDate"
                                      }
                                    }),
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        "first-day-of-week": "1",
                                        locale: "nl-nl",
                                        min: "1910-01-01",
                                        color: "#837f16"
                                      },
                                      model: {
                                        value: _vm.date.selectedDate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.date,
                                            "selectedDate",
                                            $$v
                                          )
                                        },
                                        expression: "date.selectedDate"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-textarea", {
                              attrs: { name: "comment", label: "Toelichting" },
                              model: {
                                value: _vm.comment,
                                callback: function($$v) {
                                  _vm.comment = $$v
                                },
                                expression: "comment"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "", "mt-5": "" } },
                          [
                            _c(
                              "v-layout",
                              {
                                attrs: { "justify-end": "", "align-center": "" }
                              },
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { shrink: "" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "fadedText link mr-3",
                                        on: { click: _vm.close }
                                      },
                                      [_vm._v("Annuleren")]
                                    ),
                                    _c(
                                      "MiButton",
                                      {
                                        attrs: {
                                          disabled: _vm.isDisabled,
                                          color: "primary",
                                          small: "true"
                                        },
                                        nativeOn: {
                                          click: function($event) {
                                            return _vm.saveNoAppointment($event)
                                          }
                                        }
                                      },
                                      [_vm._v(" Opslaan ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }